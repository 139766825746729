exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-delivery-models-index-js": () => import("./../../../src/pages/delivery-models/index.js" /* webpackChunkName: "component---src-pages-delivery-models-index-js" */),
  "component---src-pages-gateway-capabilities-index-js": () => import("./../../../src/pages/gateway-capabilities/index.js" /* webpackChunkName: "component---src-pages-gateway-capabilities-index-js" */),
  "component---src-pages-gateways-index-js": () => import("./../../../src/pages/gateways/index.js" /* webpackChunkName: "component---src-pages-gateways-index-js" */),
  "component---src-pages-overview-markdown-remark-parent-file-name-js": () => import("./../../../src/pages/overview/{MarkdownRemark.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-overview-markdown-remark-parent-file-name-js" */),
  "component---src-pages-platform-capabilities-index-js": () => import("./../../../src/pages/platform-capabilities/index.js" /* webpackChunkName: "component---src-pages-platform-capabilities-index-js" */),
  "component---src-templates-delivery-models-js": () => import("./../../../src/templates/delivery-models.js" /* webpackChunkName: "component---src-templates-delivery-models-js" */),
  "component---src-templates-gateway-capabilities-js": () => import("./../../../src/templates/gateway-capabilities.js" /* webpackChunkName: "component---src-templates-gateway-capabilities-js" */),
  "component---src-templates-gateways-js": () => import("./../../../src/templates/gateways.js" /* webpackChunkName: "component---src-templates-gateways-js" */),
  "component---src-templates-platform-capabilities-js": () => import("./../../../src/templates/platform-capabilities.js" /* webpackChunkName: "component---src-templates-platform-capabilities-js" */)
}

